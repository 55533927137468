import Logo from './logo.png';
import { Config } from '../../interfaces/config.interface';

const config: Config = {
  logo: Logo,
  logo_width: '200px',
  name: 'Idealist',
};

export default config;
